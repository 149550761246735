import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/tour/tour.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Tour: Build `}{`&`}{` Train a Chatbot`}</h1>
    <h2>{`Build a chatbot from historical transcripts`}</h2>
    <hr></hr>
    <p>{`Are you looking to build a chatbot? Do you have historical live agent transcripts?`}</p>
    <p>{`Dashbot’s Phrase Clustering can process live agent transcripts to identify common user messages and Intents as well as agent responses to kick start chatbot development. The data can be used to train your NLP engine.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/02/16181540/message-processing-600x467.png",
        "alt": null
      }}></img></p>
    <h2>{`Optimize Response Effectiveness`}</h2>
    <p>{`With Dashbot’s Phrase Clustering, you can identify where the chatbot may be breaking down through mishandled or unhandled Intents.`}</p>
    <p>{`The information can be used to train your NLP engine to improve response effectiveness and user satisfaction.`}</p>
    <h3>{`Identify mishandled and unhandled Intents`}</h3>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="900" src="https://www.youtube.com/embed/Gn86aDSWD9k?feature=oembed" width="1200" />
    <h3>{`Reduce failure rate`}</h3>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="900" src="https://www.youtube.com/embed/e4IGl7d-49Q?feature=oembed" width="1200" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      